<template>
  <div class="section reviews-area" id="reviews" v-if="reviewsLoaded">
    <div class="container">
      <div class="reviews-area-inner">
        <div class="h2-title">
          <h2>{{ translations("OurWorkReviews") }}</h2>
          <div class="nav-block">
            <span>{{ curSlide }}</span
            >/<span class="grey">{{ listReviews.length }}</span>
          </div>
        </div>
        <swiper
          ref="reviewsSwiper"
          @slideChange="slideChange"
          @transitionEnd="slideChange"
          :options="swiperOptions"
        >
          <swiper-slide v-for="item in listReviews" :key="item.id">
            <div class="picture">
              <img :src="'//' + serverLink + item.picture" :alt="item.name" />
            </div>
            <div class="text">
              <p>{{ item.description }}</p>
              <h3>{{ item.name }}</h3>
              <p class="grey">{{ item.position }}</p>
            </div>
          </swiper-slide>
          <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div>
        </swiper>
        <div class="mobite-visible slider-nav-block">
          <span>{{ curSlide }}</span
          >/<span class="grey">{{ listReviews.length }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VUE_APP_BACK_URL } from "@/config";
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import { mapGetters, mapActions } from "vuex";

// import style (<= Swiper 5.x)
import "swiper/css/swiper.css";

export default {
  name: "ReviewsSlider",
  data: () => {
    return {
      serverLink: VUE_APP_BACK_URL,
      curSlide: 0,
      swiperOptions: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        loopAdditionalSlides: 7,
        loop: true,
        speed: 1000,
        roundLengths: true,
        slidesPerView: 1,
        spaceBetween: 5,
        // Some Swiper option/callback...
      },
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  directives: {
    swiper: directive,
  },
  computed: {
    ...mapGetters(["translations", "reviewsLoaded", "listReviews"]),
    swiper() {
      return this.$refs.reviewsSwiper.$swiper;
    },
  },
  methods: {
    ...mapActions(["fetchReviews"]),
    slideChange() {
      this.curSlide = this.swiper.realIndex + 1;

      /*
      let active = document.querySelector(
        ".first-docs-area .swiper-slide.swiper-slide-active"
      ).nextElementSibling;
      let idx =
        this.swiper.realIndex + 1 >= this.listFirstDocs.length
          ? 0
          : this.swiper.realIndex + 1;

      this.panelsChange(idx);
      */
      //this.setCurFirstDoc(this.swiper.realIndex);
    },
  },
  async mounted() {
    await this.fetchReviews({
      lang: this.$cookies.get("user_language"),
    });
    setTimeout(() => {
      this.curSlide = this.swiper.realIndex + 1;
    }, 300);
  },
};
</script>