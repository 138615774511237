<template>
  <div class="first-docs-list" v-if="firstDocsLoaded">
    <h2 class="tablet-hidden" v-if="isCountriesLoaded && $route.meta.country">
      {{ translations("CountryExamples", [curCountry.text]) }}
    </h2>
    <h2 class="tablet-hidden" v-if="!$route.meta.country">
      {{ translations("Examples") }}
    </h2>
    <div v-if="firstDocsLoaded" class="first-docs-area-inner">
      <div class="first-doc-item" v-for="item in listFirstDocs" :key="item.id">
        <div class="image" @click="showPopup(item.id)">
          <img :src="'//' + serverLink + item.thumb" :alt="item.name" />
        </div>
        <div class="information">
          <h4>{{ item.name }}</h4>
          <p>{{ item.period }}, {{ item.price }}</p>
          <a
            v-if="item.document"
            :href="'//' + serverLink + item.document"
            :download="item.document.split('/').slice(-1)"
          >
            <svg
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M11.4344 6.75029L8.80009 9.38067V0.924683H7.20009V9.38067L4.56577 6.75029L3.4344 7.87997L8.00009 12.4388L12.5658 7.87997L11.4344 6.75029ZM16 15.3031V12.1079H14.4V15.3031H1.6V12.1079H0V15.3031C0 16.1855 0.716344 16.9008 1.6 16.9008H14.4C15.2837 16.9008 16 16.1855 16 15.3031Z"
                fill="#145980"
              />
            </svg>
            {{ translations("Download") }}
          </a>
        </div>
      </div>
      <p class="big-p" v-html="translations('AllNeedForWork')"></p>
    </div>
    <Popuper
      v-if="showModal && curFirstDoc.image"
      :contentType="'image'"
      :width="'auto'"
      @close="showModal = false"
    >
      <template v-slot:body>
        <img :src="'//' + serverLink + curFirstDoc.image" alt="" />
      </template>
    </Popuper>
  </div>
</template>

<script>
import { VUE_APP_BACK_URL } from "@/config";
import { mapGetters, mapActions } from "vuex";
import Popuper from "@/components/app/Popuper";

export default {
  name: "DocsList",
  components: { Popuper },
  data: () => {
    return {
      serverLink: VUE_APP_BACK_URL,
      showModal: false,
    };
  },
  props: {
    countryId: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters([
      "translations",
      "firstDocsLoaded",
      "listFirstDocs",
      "curFirstDoc",
      "isCountriesLoaded",
      "curCountry",
    ]),
  },
  methods: {
    ...mapActions(["fetchFirstDocs", "setCurFirstDocId"]),
    showPopup(id) {
      this.setCurFirstDocId(id);
      if (this.curFirstDoc.image) {
        this.showModal = true;
      }
    },
  },
  async mounted() {
    await this.fetchFirstDocs({
      countryId: this.countryId,
      lang: this.$cookies.get("user_language"),
    });
  },
};
</script>