<template>
  <div class="section faq-list-cont" :class="[{ block: title }]">
    <div class="container">
      <div class="h2-title" v-if="title">
        <h2>{{ translations("FAQTitle") }}</h2>
      </div>
      <div class="faq-list">
        <div
          class="faq-item"
          :class="{ active: item.id === +curFaq }"
          v-for="item in listFaqs"
          :key="item.id"
        >
          <h3 @click="setCurFaq(item.id)">{{ item.text }}</h3>
          <transition
            @enter="transitionStep1"
            @after-enter="transitionStep2"
            @before-leave="transitionStep3"
            @after-leave="transitionStep4"
            name="faq-accordion"
          >
            <div
              class="description"
              v-html="item.description"
              v-show="item.id === +curFaq"
            >
              {{ item.description }}
            </div>
          </transition>
          <svg
            @click="setCurFaq(item.id)"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.0286 5.22793L14.4 4.60918C14.2508 4.4623 14.0096 4.4623 13.8604 4.60918L8.11115 10.2561L2.36195 4.60918C2.21274 4.4623 1.97147 4.4623 1.82226 4.60918L1.19369 5.22793C1.04449 5.3748 1.04449 5.61231 1.19369 5.75918L7.84131 12.3029C7.99052 12.4498 8.23179 12.4498 8.38099 12.3029L15.0286 5.75918C15.1778 5.61231 15.1778 5.3748 15.0286 5.22793Z"
              fill="currentColor"
            />
          </svg>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "FaqAccordeon",
  computed: {
    ...mapGetters(["listFaqs", "faqsLoaded", "curFaq", "translations"]),
  },
  props: {
    title: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions(["fetchFaqs", "setCurFaq"]),
    transitionStep1(el) {
      // set the block height at the moment of its appearance
      el.style.height = el.scrollHeight + 5 + "px";
    },

    transitionStep2(el) {
      // remove inline styles from the block after animation of its appearance
      el.style.height = el.scrollHeight + "px";
    },

    transitionStep3(el) {
      // set the height of the block at the beginning of its disappearance animation
      el.style.height = el.scrollHeight + 5 + "px";
    },

    transitionStep4(el) {
      // remove inline styles from the block after the animation of its disappearance
      el.style.height = el.scrollHeight + "px";
    },
  },
  mounted() {
    this.fetchFaqs(this.$cookies.get("user_language"));
  },
};
</script>
