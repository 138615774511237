<template>
  <div class="first-advantages-list">
    <div class="h2-title">
      <h2>{{ translations("YouWillBeAble") }}</h2>
    </div>
    <div
      class="first-advantage"
      v-for="item in listAdvantages[0]"
      :key="item.id"
    >
      <img :src="'//' + serverLink + item.icon" :alt="item.text" />
      <span v-html="item.text"></span>
    </div>
    <div v-if="listAdvantages[1]">
      <hr v-if="listAdvantages[0]" />
      <div
        class="first-advantage bottom"
        v-for="item in listAdvantages[1]"
        :key="item.id"
      >
        <img :src="'//' + serverLink + item.icon" :alt="item.text" />
        <span v-html="item.text"></span>
      </div>
    </div>
  </div>
</template>

<script>
import { VUE_APP_BACK_URL } from "@/config";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "FirstAdvantagesList",
  data: () => {
    return {
      serverLink: VUE_APP_BACK_URL,
    };
  },
  computed: {
    ...mapGetters(["listAdvantages", "advantagesLoaded", "translations"]),
  },
  methods: {
    ...mapActions(["fetchFirstAdvantages"]),
  },
  mounted() {
    if (!this.advantagesLoaded) {
      this.fetchFirstAdvantages(this.$cookies.get("user_language"));
    }
  },
};
</script>
